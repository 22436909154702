import React from 'react';
import PropTypes from 'prop-types';

const SliderNav = ({slides, onNavClick, current}) => {

    return (
        <ul className="slide-nav">
            {slides.map((slide, index) => {
                return (
                    <li
                        key={slide.node.wordpress_id}
                        onClick={(e) => onNavClick(index)}
                        className={index === current ? 'active' : ''}
                    >
                        <img
                            src={slide.node.acf.slide_thumbnail.localFile.childImageSharp.original.src}
                            alt={`Switch to slide ${index}`} />
                    </li>
                );
            })}
        </ul>
    );
};

SliderNav.propTypes = {
    slides: PropTypes.array.isRequired,
    onNavClick: PropTypes.func.isRequired,
    current: PropTypes.number,
};

export default SliderNav;

