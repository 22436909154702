export default {
    "footer" : {
        "main-text" : "Web and mobile solutions that build better, stronger brands"
    },
    "home": {
        "portfolio-component" : {
            "heading": "Our work. 1000+ projects completed, 200+ happy customers",
            "subheading": "A selection of web, branding and print projects",
            "cta" : "View our portfolio"
        },
        "blog-component" : {
            "heading": "We help to make the web a better place",
            "subheading": "Blog posts on everything ‘web’",
            "cta" : "See all entries"
        },
        "testimonials-component" : {
            "heading": "Clients trust us to help strengthen their brands",
            "subheading": "Quotes from our happy customers",
            "cta" : "Read more testimonials"
        },
    },
    "blog": {
        "heading": "Blog",
        "subheading": "The Web is about sharing and so are we",
    },
    "portfolio": {
        "heading": "Our Work",
        "subheading": "How we have helped build stronger brands in the digital world",
        "pageTitle": "Portfolio of websites for athletes and businesses",
    },
    "contact": {
        "heading": "Contact",
        "subheading":"Contact LowGravity to discuss your web needs",
    },
    "author": {
        "heading": "Written by: ",
        "subheading": "All posts written by this author",
    },
}
