import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Header from './header';
import Footer from './footer';
import '../assets/styles/app.scss';

import { ColorContext } from '../utils/color-context';

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.changeHeaderColor = (headerColor) => {
            this.setState({
                headerColor
            });
        };

        this.state = {
            headerColor: 'alizarin',
            changeHeaderColor: this.changeHeaderColor
        }
    }

    render() {
        const {children} = this.props;

        return (
            <>
                <Helmet
                    title="Responsive design & web development | LowGravity.pl"
                >
                    <html lang="en" />
                </Helmet>
                <ColorContext.Provider value={this.state}>
                    <div className="sticky-footer-wrapper">
                        <Header />
                        <div className="site-wrapper">
                            {children}
                        </div>
                        <Footer />
                    </div>
                </ColorContext.Provider>
            </>
        );
    }
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
