import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import BlogListEntry from '../blog-list-entry';

const FeaturedBlog = ({posts, siteContent}) => (
    <section className="featured-blog-component">
        <div className="component-wrapper">
            <header>
                <h2>{siteContent.home['blog-component'].heading}</h2>
                <h3>{siteContent.home['blog-component'].subheading}</h3>
            </header>
            <div className="latest-blog">
                {posts.map(({node}) => (
                    <BlogListEntry post={node} key={node.slug} />
                ))}
            </div>
            <footer>
                <Link
                    to="/category/blog/"
                    className="button"
                >
                    {siteContent.home['blog-component'].cta}
                </Link>
            </footer>
        </div>
    </section>
);

FeaturedBlog.propTypes = {
    posts: PropTypes.array.isRequired,
    siteContent: PropTypes.object.isRequired,
};

export default FeaturedBlog;
