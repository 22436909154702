import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import PortfolioListEntry from '../portfolio-list-entry';

const FeaturedWorks = ({projects, siteContent}) => {

    const allProjectTypes = projects.reduce((acc, {node: curr}) => {
        acc[curr.wordpress_id] = curr.name;
        return acc;
    }, {});

    return (
        <section className="featured-works-component">
            <div className="component-wrapper">
                <header>
                    <h2>{siteContent.home['portfolio-component'].heading}</h2>
                    <h3>{siteContent.home['portfolio-component'].subheading}</h3>
                </header>
                <div className="featured-works">
                    <ul className="projects-list">
                        {projects.map(({node}) => (
                            <PortfolioListEntry
                                post={node}
                                types={allProjectTypes}
                                key={node.slug}
                            />
                        ))}
                    </ul>
                </div>
                <footer>
                    <Link
                        to="/portfolio/"
                        className="button"
                    >
                        {siteContent.home['portfolio-component'].cta}
                    </Link>
                </footer>
            </div>
        </section>
    );
};

FeaturedWorks.propTypes = {
    projects: PropTypes.array.isRequired,
    siteContent: PropTypes.object.isRequired,
};

export default FeaturedWorks;
