export function getRequestURI(url) {
    const parts = url.split("/")
                     .filter(v => v !== '')
                     .slice(2)
                     .join('/');

    return parts === '' ? '/' :`/${parts}/`;
}

// Thanks to https://jsfiddle.net/BideoWego/muuvvof8/
export const HtmlEntities = function() {};

HtmlEntities.map = {
    "&apos;": "'",
    "&lt;": "<",
    "&gt;": ">",
    "&nbsp;": " ",
    "&cent;": "¢",
    "&pound;": "£",
    "&uml;": "¨",
    "&copy;": "©",
    "&reg;": "®",
    "&deg;": "°",
    "&plusmn;": "±",
    "&sup2;": "²",
    "&sup3;": "³",
    "&acute;": "´",
    "&times;": "×",
    "&circ;": "ˆ",
    "&tilde;": "˜",
    "&ndash;": "–",
    "&mdash;": "—",
    "&lsquo;": "‘",
    "&rsquo;": "’",
    "&sbquo;": "‚",
    "&ldquo;": "“",
    "&rdquo;": "”",
    "&bdquo;": "„",
    "&euro;": "€",
    "&minus;": "−",
    "&#038;": "&",
    "&#8211;": "–",
    "&#8212;": "—",
    "&#8216;": "‘"
};

HtmlEntities.decode = function(string) {
    var entityMap = HtmlEntities.map;
    for (var entity in entityMap) {
        var value = entityMap[entity];
        var regex = new RegExp(entity, 'g');
        string = string.replace(regex, value);
    }
    string = string.replace(/&quot;/g, '"');
    string = string.replace(/&amp;/g, '&');
    return string;
}