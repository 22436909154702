import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import siteContent from '../config/site-content';

const Footer = () => (
    <StaticQuery
        query={footerQuery}
        render={renderQueryResults}
    />
);

const footerQuery = graphql`
    query footerQuery {
        wordpressAcfOptions {
            options {
                lowgravity_socials {
                    social_network
                    social_url
                }
            }
        }
    }
`;

const renderQueryResults = (data) => (
    <footer className="main-footer">
        <div className="primary-footer">
            <div className="component-wrapper">
                <Link
                    to="/"
                    className="site-logo"
                >
                    LowGravity
                </Link>
                <p>{siteContent.footer['main-text']}</p>
                <ul className="social-networks">
                    {data.wordpressAcfOptions.options.lowgravity_socials.map((social) => (
                        <li key={social.social_url}>
                            <a
                                href={social.social_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span className={`social-icon ${social.social_network}`}></span> {social.social_network}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
        <div className="secondary-footer">
            <div className="component-wrapper">
                <p>All rights reserved. © 2019 by LowGravity</p>
                <Link
                    to="/privacy-and-cookies/">
                    Privacy and cookies policy
                </Link>
            </div>
        </div>
    </footer>
);

export default Footer;
