import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash.get';


const PortfolioListEntry = ({post, types}) => {
    const imageRes = get(post, 'featured_media.localFile.childImageSharp.fluid', '');

    const imageTag = imageRes ? <Img fluid={imageRes} key={imageRes.src} /> : '';
    return (
        <li>
            <Link to={`/portfolio/${post.slug}/`}>
                <div className="thumbnail">
                    { imageTag }
                </div>
                <h2
                    dangerouslySetInnerHTML={{
                        __html: `${post.title} <span>/ ${post.project_types.map(id => types[id]).join(" / ")}</span>`
                    }}
                ></h2>
                <h3>{post.acf.project_subheading}</h3>
            </Link>
        </li>
    );
};

PortfolioListEntry.propTypes = {
    post: PropTypes.object.isRequired,
    types: PropTypes.object.isRequired,
};

export default PortfolioListEntry;
