import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import PostMeta from '../components/post-meta';
import BlogImage from '../components/blog-image';

const BlogListEntry = ({post}) => (
    <div className="blog-entry">
        <div className="column">
            <Link to={`/blog/${post.slug}/`}>
                <BlogImage
                    original_src={post.featured_media.localFile.childImageSharp.original.src}
                    background_color={post.acf.featured_image_background_color}
                />
            </Link>
        </div>
        <div className="column">
            <Link to={`/blog/${post.slug}/`}>
                <h3
                    dangerouslySetInnerHTML={{__html: post.title}}
                ></h3>
            </Link>
            <PostMeta date={post.date} author={post.author} />
            <div className="excerpt"
                dangerouslySetInnerHTML={{__html: post.excerpt}}
            ></div>
            <Link to={`/blog/${post.slug}/`} className="button readmore">
                Read this post
            </Link>
        </div>
    </div>
);

BlogListEntry.propTypes = {
    post: PropTypes.object.isRequired,
};

export default BlogListEntry;