import React from 'react';
import Slide from './slider-item';
import SliderNav from './slider-nav';
import { ColorContext } from '../../utils/color-context';
import PropTypes from 'prop-types';

const sliderInterval = 5000;

class Slider extends React.Component {
    constructor(props) {
        super(props);

        const {slides} = props;

        this.state = {
            currentSlide: null,
            slides,
            totalSlides:  slides.length,
            intervalId:   null
        };
    }

    setCurrentSlide(index) {
        this.setState({
            currentSlide: index
        });

        this.context.changeHeaderColor(
            this.state.slides[index].node.acf.slide_primary_color
        );
    }

    componentDidMount() {
        this.setCurrentSlide(0);
        this.createRotationInterval();
    }

    componentWillUnmount() {
        this.destroyRotationInterval();
    }

    createRotationInterval() {
        let interval = setInterval(this.rotate.bind(this), sliderInterval);
        this.setState({
            intervalId: interval
        });
    }

    destroyRotationInterval() {
        clearInterval(this.state.intervalId);
    }

    rotate() {
        const newIndex = (this.state.currentSlide + 1) % this.state.totalSlides;
        this.setCurrentSlide(newIndex);
    }

    resetInterval() {
        this.destroyRotationInterval();
        this.createRotationInterval();
    }

    onNavClick(index) {
        this.setCurrentSlide(index);
        this.resetInterval();
    }

    setNavWrapperClass() {
        if (this.state.currentSlide !== null && this.state.slides && this.state.slides[this.state.currentSlide]) {
            return this.state.slides[this.state.currentSlide].node.acf.slide_secondary_color;
        }
        return '';
    }

    render() {
        const {slides} = this.state;
        return (
            <div className="slider">
                <ul className="slides">
                    {slides.map((slide, index) => {
                        const isCurrent = index === this.state.currentSlide;
                        return (
                            <Slide slide={slide.node} key={slide.node.wordpress_id} current={isCurrent} />
                        )
                    })}
                </ul>
                <div
                    className={`slider-navigation-wrapper bg-${this.setNavWrapperClass()}`}
                >
                    <SliderNav
                        slides={slides}
                        onNavClick={this.onNavClick.bind(this)}
                        current={this.state.currentSlide}
                    />
                </div>
            </div>
        )
    }
}

Slider.propTypes = {
    slides: PropTypes.array.isRequired,
};

Slider.contextType = ColorContext;

export default Slider;
