import React from 'react';
import { Link } from 'gatsby';
import { getRequestURI } from '../utils/helpers';
import PropTypes from 'prop-types';

const PostMeta = ({date, author}) => (
    <div className="post-meta">
        {date} <strong>by <Link to={getRequestURI(author.link)}>{author.name}</Link></strong>
    </div>
);

PostMeta.propTypes = {
    date: PropTypes.string.isRequired,
    author: PropTypes.object.isRequired,
};

export default PostMeta;