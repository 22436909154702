import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import TopMenu from './top-menu';
import { ColorContext } from '../utils/color-context';

const Header = () => (
    <StaticQuery
        query={headerQuery}
        render={renderQueryResults}
    />
);

const headerQuery = graphql`
    query headerQuery {
        site {
            siteMetadata {
                title
            }
        }
        wordpressWpApiMenusMenusItems(name: {eq: "top_menu"}) {
            items {
                title
                url
                object_id
                classes
            }
        }
    }
`;

const renderQueryResults = (data) => {
    return (
        <ColorContext.Consumer>
            {({headerColor}) => {
                return (
                    <header className={`main-header bg-${headerColor}`}>
                        <div className="component-wrapper">
                            <h1>
                                <Link to="/" className="site-logo">
                                    {data.site.siteMetadata.title}
                                </Link>
                            </h1>
                            <TopMenu items={data.wordpressWpApiMenusMenusItems.items} />
                        </div>
                    </header>
                );
            }}
        </ColorContext.Consumer>
    );
};

export default Header;
