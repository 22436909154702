import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import siteContent from '../config/site-content';
import Layout from '../components/layout';
import Slider from '../components/slider/slider';
import FeaturedWorks from '../components/home/featured-works';
import NumbersStripe from '../components/home/numbers-stripe';
import FeaturedBlog from '../components/home/featured-blog';
import FeaturedClients from '../components/home/featured-clients';

const IndexPage = ({data}) => {

    const slides   = get(data, 'allWordpressWpSlide.edges', []);
    const projects = get(data, 'allWordpressWpPortfolio.edges', []);
    const posts    = get(data, 'allWordpressPost.edges', []);
    const clients  = get(data, 'allWordpressWpClients.edges', []);
    const logos    = get(data, 'wordpressPage.acf.lowgravity_logos', []);

    return (
        <Layout>
            <Slider
                slides={slides}
            />
            <FeaturedWorks
                projects={projects}
                siteContent={siteContent}
            />
            <NumbersStripe />
            <FeaturedBlog
                posts={posts}
                siteContent={siteContent}
            />
            <FeaturedClients
                clients={clients}
                siteContent={siteContent}
                logos={logos}
            />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        allWordpressWpSlide: PropTypes.object.isRequired,
        allWordpressWpPortfolio: PropTypes.object.isRequired,
        allWordpressPost: PropTypes.object.isRequired,
        allWordpressWpClients: PropTypes.object.isRequired,
        wordpressPage: PropTypes.object.isRequired,
    })
};

export default IndexPage;

export const pageQuery = graphql`
    query IndexQuery {
        allWordpressWpPortfolio(limit: 2) {
            edges {
                node {
                    project_types
                    title
                    id
                    slug
                    acf {
                        project_subheading
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 605){
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressPost(limit: 2) {
            edges {
                node {
                    title
                    slug
                    excerpt
                    date(formatString: "Do [of] MMMM, YYYY")
                    author {
                        name
                        link
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                original {
                                    src
                                }
                            }
                        }
                    }
                    acf {
                        featured_image_background_color
                    }
                }
            }
        }
        allWordpressWpProjectTypes {
            edges {
                node {
                    name
                    wordpress_id
                }
            }
        }
        allWordpressWpSlide(
            sort: {
                fields: date
                order: ASC
            }
        ) {
            edges {
                node {
                    wordpress_id
                    title
                    content
                    acf {
                        slide_primary_color
                        slide_secondary_color
                        slide_button_text
                        slide_url{
                            url
                        }
                        slide_thumbnail {
                            localFile {
                                childImageSharp {
                                    original {
                                        src
                                    }
                                }
                            }
                        }
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 605){
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressWpClients {
            edges {
                node {
                    id
                    content
                    acf {
                        testimonial_person_name
                        testimonial_person_position
                        work_done
                        client_featured
                        secondary_featured_image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 205){
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        wordpressPage(slug: {eq: "about"}) {
            acf {
                lowgravity_logos {
                    logo_image {
                        id
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 350 ){
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
