import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const Slide = ({slide, current}) => {
    const imageRes = slide.featured_media.localFile.childImageSharp.fluid;

    return (
        <li
            className={`slide bg-${slide.acf.slide_primary_color}`}
            style={{
                display: current ? 'block' : 'none'
            }}
        >
            <div className="slide-wrapper">
                <div className="slide-img-wrapper">
                    <Img fluid={imageRes} key={imageRes.src} />
                </div>
                <div className="slide-content-wrapper">
                    <h2>
                        {slide.title}
                    </h2>
                    <div dangerouslySetInnerHTML={{__html: slide.content}} />
                    <Link
                        className="slide-cta"
                        to={slide.acf.slide_url.url}
                    >
                        {slide.acf.slide_button_text}
                    </Link>
                </div>
            </div>
        </li>
    );
};

Slide.propTypes = {
    slide: PropTypes.object.isRequired,
    current:PropTypes.bool.isRequired
};

export default Slide
