import React from 'react';
import SingleMenuItem from '../components/single-menu-item';
import PropTypes from 'prop-types';

const TopMenu = ({items}) => (
    <nav>
        <ul>
            {items.map((item) => (
                <SingleMenuItem item={item} key={item.object_id} />
            ))}
        </ul>
    </nav>
);

TopMenu.propTypes = {
    items: PropTypes.array.isRequired,
};

export default TopMenu;
