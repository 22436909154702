import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import TestimonialsListEntry from '../testimonials-list-entry';

const FeaturedClients = ({clients, logos, siteContent}) => {
    const testimonials      = clients.filter(({node}) => node.acf.client_featured !== false);
    const randomTestimonial = testimonials[Math.floor(Math.random() * testimonials.length)];

    return (
        <section className="featured-clients-component">
            <div className="testimonial-header">
                <header>
                    <h2>{siteContent.home['testimonials-component'].heading}</h2>
                    <h3>{siteContent.home['testimonials-component'].subheading}</h3>
                </header>
            </div>
            <div className="featured-testimonial">
                <div className="component-wrapper">
                    <TestimonialsListEntry
                        key={randomTestimonial.node.id}
                        post={randomTestimonial.node}
                        isFeatured={true}
                    />
                </div>
            </div>
            <div className="testimonial-footer">
                <div className="logos-stripe">
                    <ul>
                        {logos.map(({logo_image}) => (
                            <li key={logo_image.id}>
                                <Img fluid={logo_image.localFile.childImageSharp.fluid} />
                            </li>
                        ))}
                    </ul>
                </div>
                <footer>
                    <Link
                        to="/testimonials/"
                        className="button"
                    >
                        {siteContent.home['testimonials-component'].cta}
                    </Link>
                </footer>
            </div>
        </section>
    );
};

FeaturedClients.propTypes = {
    clients: PropTypes.array.isRequired,
    logos: PropTypes.array.isRequired,
    siteContent: PropTypes.object.isRequired,
};

export default FeaturedClients;
