import React from 'react';
import PropTypes from 'prop-types';

const BlogImage = ({background_color, original_src}) => (
    <div className={"blog-image-wrapper" + (background_color ? ` bg-${background_color}` : '')}>
        <img src={original_src} alt="featured" />
    </div>
);

BlogImage.propTypes = {
    background_color: PropTypes.string,
    original_src: PropTypes.string.isRequired,
};

export default BlogImage;
