import React from 'react';
import { Link } from 'gatsby';
import { getRequestURI } from '../utils/helpers';
import PropTypes from 'prop-types';

const SingleMenuItem = ({item}) => (
    <li className={item.classes ? item.classes : null}>
        <Link
            to={`${getRequestURI(item.url)}`}
            getProps={({href, isPartiallyCurrent, isCurrent, location}) => {
                if (
                        (href !== '/' && isPartiallyCurrent)
                        || (href === '/' && isCurrent)
                        || ((location.pathname.startsWith('/blog/') || location.pathname.startsWith('/tag/')) && href === '/category/blog/')
                    ) {
                    return {className: 'active'};
                } else {
                    return null;
                }
            }}
        >
            {item.title}
        </Link>
    </li>
);

SingleMenuItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default SingleMenuItem;