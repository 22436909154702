import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const TestimonialsListEntry = ({post, isFeatured}) => {
    let imageRes = "";
    if (!isFeatured) {
        imageRes = post.featured_media.localFile.childImageSharp.fluid;
    } else {
        imageRes = post.acf.secondary_featured_image.localFile.childImageSharp.fluid;
    }

    return (
        <div className="testimonials-list-entry">
            <div className="testimonial-thumbnail">
                <Img fluid={imageRes} key={imageRes.src} />
                <div className="work-done">
                    {post.acf.work_done}
                </div>
            </div>
            <div className="testimonial-content">
                <div dangerouslySetInnerHTML={{__html: post.content}}></div>
                <h3>
                    {post.acf.testimonial_person_name}
                    <span> / {post.acf.testimonial_person_position}</span>
                </h3>
            </div>
        </div>
    );
};

TestimonialsListEntry.propTypes = {
    post: PropTypes.object.isRequired,
    isFeatured: PropTypes.bool,
};

TestimonialsListEntry.defaultProps = {
    isFeatured: false,
};

export default TestimonialsListEntry;