import React from 'react';

const NumbersStripe = () => (
    <section className="numbers-stripe-component">
        <ul className="component-wrapper">
            <li><span>14</span>years on market</li>
            <li><span>600+</span>projects finished</li>
            <li><span>121+</span>satisfied customers</li>
            <li><span>17</span>countries</li>
            <li><span>900K+</span>lines of code</li>
            <li><span>6K</span>PSD files</li>
        </ul>
    </section>
);

export default NumbersStripe;
